import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, map, Observable, share, tap } from "rxjs";
import {
  Property,
  PropertyFeature,
  PropertyType,
  PropertyStatus,
  PropertyCondition,
  PropertyHeatingDevice,
  PropertyHeatingType,
  PropertyWarmWaterDevice,
  PropertyWaterWaste,
  PropertyFloor,
  PaginatedSmallProperty,
  SmallProperty,
} from "../models/property.models";
import { DataService } from "./data.service";
import { Pagination } from "../models/pagination.models";
import { SpinnerService } from "./spinner.service";
import { TranslationService } from "./translation.service";
import { SearchParams } from "../models/settings.models";

@Injectable({
  providedIn: "root",
})
export class PropertyService {
  private propertyFeatures: PropertyFeature[] = [];
  private conditions: PropertyCondition[] = [];
  private types: PropertyType[] = [];
  private heatingDevices: PropertyHeatingDevice[] = [];
  private heatingTypes: PropertyHeatingType[] = [];
  private warmWaterDevices: PropertyWarmWaterDevice[] = [];
  private waterWaste: PropertyWaterWaste[] = [];
  private floors: PropertyFloor[] = [];
  private propertyStatus: PropertyStatus[] = [];

  // Needs to be public because on editProperty we modify it
  public myProperties: SmallProperty[] = [];

  public hasProperties: boolean = false;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private spinnerService: SpinnerService,
    private translationService: TranslationService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  /**
   * Endpoint used to retrieve all the property items
   * This endpoint queries the database based on the given search fields, the sorting mechanism and the pagination itself
   * @param searchFields the searchfield as specified in the app-properties-search component
   * @param sorting the type of sorting, a string, as specified in app-properties-toolbar
   * @param pagination the pagination model itself
   * @returns
   */
  public downloadProperties(
    searchFields: SearchParams,
    type: "sale" | "rent",
    sorting: string,
    pagination: Pagination
  ): Observable<PaginatedSmallProperty> {
    // Pass the search and pagination parameters in the query
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: new HttpParams(),
    };
    // For now add the search fields here
    // ToDo refactor this to component level
    searchFields["status"] = type;
    httpOptions.params = httpOptions.params.append(
      "searchFields",
      JSON.stringify(searchFields)
    );
    httpOptions.params = httpOptions.params.append("sorting", sorting);
    httpOptions.params = httpOptions.params.append("page", pagination.page);
    if (pagination.itemsPerPage) {
      httpOptions.params = httpOptions.params.append(
        "items_per_page",
        pagination.itemsPerPage
      );
    }
    return this.http.get<PaginatedSmallProperty>(
      `${this.dataService.backendUrl}/properties`,
      httpOptions
    );
  }

  /**
   * Downloads all the properties of the given agent from the backend
   * Call it using the getMyProperties function below this will result in only 1 call to the backend */
  public downloadMyProperties(): Observable<SmallProperty[]> {
    return this.http
      .get<
        SmallProperty[]
      >(`${this.dataService.backendUrl}/myproperties`, this.httpOptions)
      .pipe(
        tap((properties) => {
          this.hasProperties = properties.length > 0;
          this.myProperties = properties;
        })
      );
  }

  /**
   * Function that returns all the propertyies of the given agent
   * it downloads them beforehand if required
   * In case you would like to perform a forced download use the downloadMyProperties function
   * @ereturns returns a promise for the result
   */
  public async getMyProperties(): Promise<SmallProperty[]> {
    // In case we dont have the myproperties yet convert the subscription to a promise and wait for the result
    if (!this.myProperties.length) {
      await firstValueFrom(this.downloadMyProperties()).then((data) => {
        this.myProperties = data;
      });
    }
    return this.myProperties;
  }

  /**
   * This endpoint returns a given property from an id
   * @param id the id of the property
   * @returns the property itself
   */
  public downloadPropertyById(id: string): Observable<Property> {
    return this.http.get<Property>(
      this.dataService.backendUrl + "/propertyById/" + id,
      this.httpOptions
    );
  }

  // Adds a view to the property model
  public addPropertyView(id: string) {
    return this.http.post(
      this.dataService.backendUrl + "/properties/addView",
      { id: id },
      this.httpOptions
    );
  }

  /*****************
   *  Property Types
   **********/
  private downloadPropertyTypes(): Observable<PropertyType[]> {
    return this.http
      .get<
        PropertyType[]
      >(this.dataService.backendUrl + "/property_types", this.httpOptions)
      .pipe(
        map((types) => {
          const translatedTypes: PropertyType[] = [];
          for (const type of types) {
            translatedTypes.push({
              id: type.id,
              name: this.translationService.translatePropertyType(type),
            });
          }
          return translatedTypes;
        })
      );
  }
  public async getPropertyTypes(): Promise<PropertyType[]> {
    // In case we dont have the property types yet convert the subscription to a promise and wait for the result
    if (!this.types.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyTypes())
        .then((data) => {
          this.types = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.types;
  }

  /*****************
   *  Property Heating Device
   **********/
  private downloadHeatingDevices(): Observable<PropertyHeatingDevice[]> {
    return this.http
      .get<
        PropertyHeatingDevice[]
      >(this.dataService.backendUrl + "/property_heating_devices", this.httpOptions)
      .pipe(
        map((devices) => {
          const translatedHeatingDevices: PropertyHeatingDevice[] = [];
          for (const device of devices) {
            translatedHeatingDevices.push({
              id: device.id,
              apimo_id: device.apimo_id,
              name: this.translationService.translatePropertyHeatingDevice(
                device
              ),
            });
          }
          return translatedHeatingDevices;
        })
      );
  }
  public async getHeatingDevices(): Promise<PropertyHeatingDevice[]> {
    if (!this.heatingDevices.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadHeatingDevices())
        .then((data) => {
          this.heatingDevices = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.heatingDevices;
  }

  /*****************
   *  Property Waste Water
   **********/
  private downloadPropertyWaterWastes(): Observable<PropertyWaterWaste[]> {
    return this.http
      .get<
        PropertyWaterWaste[]
      >(this.dataService.backendUrl + "/property_water_wastes", this.httpOptions)
      .pipe(
        map((waterWastes) => {
          const translatedwaterWastes: PropertyWaterWaste[] = [];
          for (const waterWaste of waterWastes) {
            translatedwaterWastes.push({
              id: waterWaste.id,
              apimo_id: waterWaste.apimo_id,
              name: this.translationService.translatePropertyWaterWaste(
                waterWaste
              ),
            });
          }
          return translatedwaterWastes;
        })
      );
  }

  public async getPropertyWaterWastes(): Promise<PropertyWaterWaste[]> {
    if (!this.waterWaste.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyWaterWastes())
        .then((data) => {
          this.waterWaste = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.waterWaste;
  }

  /*****************
   *  Property Floors
   **********/
  private downloadPropertyFloors(): Observable<PropertyFloor[]> {
    return this.http
      .get<
        PropertyFloor[]
      >(this.dataService.backendUrl + "/property_floors", this.httpOptions)
      .pipe(
        map((floors) => {
          const translatedFloors: PropertyFloor[] = [];
          for (const floor of floors) {
            translatedFloors.push({
              id: floor.id,
              apimo_id: floor.apimo_id,
              name: this.translationService.translatePropertyFloor(floor),
            });
          }
          return translatedFloors;
        })
      );
  }

  public async getPropertyFloors(): Promise<PropertyFloor[]> {
    if (!this.floors.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyFloors())
        .then((data) => {
          this.floors = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.floors;
  }

  /*****************
   *  Property Heating Types
   **********/
  private downloadPropertyHeatingTypes(): Observable<PropertyHeatingType[]> {
    return this.http
      .get<
        PropertyHeatingType[]
      >(this.dataService.backendUrl + "/property_heating_types", this.httpOptions)
      .pipe(
        map((heatingTypes) => {
          const translatedheatingTypes: PropertyHeatingType[] = [];
          for (const type of heatingTypes) {
            translatedheatingTypes.push({
              id: type.id,
              apimo_id: type.apimo_id,
              name: this.translationService.translatePropertyHeatingType(type),
            });
          }
          return translatedheatingTypes;
        })
      );
  }

  public async getPropertyHeatingTypes(): Promise<PropertyHeatingType[]> {
    // In case we dont have the heating types yet convert the subscription to a promise and wait for the result
    if (!this.heatingTypes.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyHeatingTypes())
        .then((data) => {
          this.heatingTypes = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.heatingTypes;
  }

  /*****************
   *  Property Warm Water Devices
   **********/
  private downloadPropertyWarmWaterDevices(): Observable<
    PropertyWarmWaterDevice[]
  > {
    return this.http
      .get<
        PropertyWarmWaterDevice[]
      >(this.dataService.backendUrl + "/property_warm_water_devices", this.httpOptions)
      .pipe(
        map((devices) => {
          const translatedWarmWaterDevices: PropertyWarmWaterDevice[] = [];
          for (const device of devices) {
            translatedWarmWaterDevices.push({
              id: device.id,
              apimo_id: device.apimo_id,
              name: this.translationService.translatePropertyWarmWaterDevice(
                device
              ),
            });
          }
          return translatedWarmWaterDevices;
        })
      );
  }

  public async getPropertyWarmWaterDevices(): Promise<
    PropertyWarmWaterDevice[]
  > {
    // In case we dont have the warm water devices yet convert the subscription to a promise and wait for the result
    if (!this.warmWaterDevices.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyWarmWaterDevices())
        .then((data) => {
          this.warmWaterDevices = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.warmWaterDevices;
  }

  /*****************
   *  Property Conditions
   **********/
  private downloadPropertyConditions(): Observable<PropertyCondition[]> {
    return this.http
      .get<
        PropertyCondition[]
      >(this.dataService.backendUrl + "/property_conditions", this.httpOptions)
      .pipe(
        map((conditions) => {
          const translatedConditions: PropertyCondition[] = [];
          for (const condition of conditions) {
            translatedConditions.push({
              id: condition.id,
              apimo_id: condition.apimo_id,
              name: this.translationService.translatePropertyCondition(
                condition
              ),
            });
          }
          return translatedConditions;
        })
      );
  }

  public async getPropertyConditions(): Promise<PropertyCondition[]> {
    // In case we dont have the property conditions yet convert the subscription to a promise and wait for the result
    if (!this.conditions.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyConditions())
        .then((data) => {
          this.conditions = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.conditions;
  }

  /*****************
   *  Property Features
   **********/
  private downloadPropertyFeatures(): Observable<PropertyFeature[]> {
    return this.http
      .get<
        PropertyFeature[]
      >(this.dataService.backendUrl + "/property_features", this.httpOptions)
      .pipe(
        map((features) => {
          const translatedPropertyFeatures: PropertyFeature[] = [];
          for (const feature of features) {
            translatedPropertyFeatures.push({
              id: feature.id,
              selected: feature.selected,
              type: feature.type,
              name: this.translationService.translatePropertyFeature(feature),
            });
          }
          return translatedPropertyFeatures;
        })
      );
  }

  public async getPropertyFeatures(): Promise<PropertyFeature[]> {
    // In case we dont have the property features yet convert the subscription to a promise and wait for the result
    if (!this.propertyFeatures.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyFeatures())
        .then((data) => {
          this.propertyFeatures = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.propertyFeatures;
  }

  /**
   * Downloads all the possible property statuses from the backend
   * This function should only be called once so it has been set to private
   * Call it using the getPropertyStatus function below this will result in only 1 call to the backend
   * It was changed to a variable from a function to ensure that the debounceTIme property is used correctly
   * and not 5 calls to the backedn are made at startup */
  private downloadPropertyStatus: Observable<PropertyStatus[]> = this.http
    .get<
      PropertyStatus[]
    >(this.dataService.backendUrl + "/property_status", this.httpOptions)
    .pipe(
      map((statuses) => {
        const translatedStatuses: PropertyStatus[] = [];
        for (const status of statuses) {
          translatedStatuses.push({
            id: status.id,
            type: status.type,
            name_en: status.name,
            name: this.translationService.translatePropertyStatus(status),
          });
        }
        return translatedStatuses;
      }),
      share()
    );

  /**
   * Functions that downloads all the property statuses
   * All the statuses are downloaded beforehand if not done yet
   * @ereturns returns a promise for the result
   */
  public async getPropertyStatus(): Promise<PropertyStatus[]> {
    // In case we dont have the status yet convert the subscription to a promise and wait for the result
    if (!this.propertyStatus.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyStatus)
        .then((data) => {
          this.propertyStatus = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.propertyStatus;
  }

  /**
   * Function that returns the possible status given a current status id
   * All the statuses are downloaded beforehand
   * @ereturns returns a promise for the result
   */
  public async getPropertyStatusById(
    propertyStatusId: string
  ): Promise<PropertyStatus> {
    // In case we dont have the property status yet convert the subscription to a promise and wait for the result
    if (!this.propertyStatus.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadPropertyStatus)
        .then((data) => {
          this.propertyStatus = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }

    /* Filter the property status based on what the user is allowed to see  
       This is done by providing the current status and chosing based on that 
       The allowedStatus array is used to filter out the irrelevant statuses */

    return this.propertyStatus.filter((status) => {
      return status.id == propertyStatusId;
    })[0];
  }

  /**
   * Download all the favorites from the backend
   * Set to private because it should be called using the getMyFavorites function if required */
  public downloadMyFavorites(
    pagination: Pagination
  ): Observable<PaginatedSmallProperty> {
    // Pass the search and pagination parameters in the query
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: new HttpParams(),
    };
    httpOptions.params = httpOptions.params.append("page", pagination.page);
    if (pagination.itemsPerPage) {
      httpOptions.params = httpOptions.params.append(
        "items_per_page",
        pagination.itemsPerPage
      );
    }
    return this.http.get<PaginatedSmallProperty>(
      this.dataService.backendUrl + "/properties/favorites",
      httpOptions
    );
  }

  public addToFavorites(propertyId: string) {
    return this.http.post(
      this.dataService.backendUrl + "/properties/favorites",
      { id: propertyId },
      this.httpOptions
    );
  }

  public triggerFacebook(propertyId: string) {
    return this.http.post(
      this.dataService.backendUrl + "/socials/add_to_facebook",
      { id: propertyId },
      this.httpOptions
    );
  }

  public deleteFromFavorites(propertyId: string) {
    return this.http.delete(
      this.dataService.backendUrl + "/properties/favorites/" + propertyId,
      this.httpOptions
    );
  }

  // Submit a property, gallery has to be uploaded seperately
  public submitProperty(propertyData: any): Observable<Property> {
    return this.http.post<Property>(
      this.dataService.backendUrl + "/properties-submit",
      propertyData,
      this.httpOptions
    );
  }

  // Edit a property.
  public editProperty(propertyData: any): Observable<Property> {
    return this.http.put<Property>(
      this.dataService.backendUrl + "/properties/update",
      propertyData,
      this.httpOptions
    );
  }

  /**
   * Uploads an image for a given property
   * @param propertyId the property id to which we add the image
   * @param base64_image the corresponding base64 encoded image
   * @param rank the rank (elements are shown in increasing order)
   * @returns
   */
  public uploadPropertyPicture(
    propertyId: string,
    base64_image: string,
    rank: number
  ) {
    let data = {
      propertyId: propertyId,
      base64Image: base64_image,
      rank: rank,
    };
    return this.http.post(
      this.dataService.backendUrl + "/property/gallery",
      data,
      this.httpOptions
    );
  }

  public deleteProperty(propertyId: string) {
    return this.http.delete(
      this.dataService.backendUrl + `/properties/${propertyId}/delete`,
      this.httpOptions
    );
  }

  public importPropertyFromFile(agentEmail: string, jsonFile: any) {
    return this.http.post(
      `${this.dataService.backendUrl}/importer/property_json?agent=${agentEmail}`,
      jsonFile,
      this.httpOptions
    );
  }

  /**
   * Get the thumbnail image of the given image url
   */
  public getThumbnailPicture(imageUrl: string): string {
    const splittedImage = imageUrl.split(".");
    const extension = splittedImage[splittedImage.length - 1];
    const imageFilename = splittedImage.slice(0, -1).join(".");
    return `${imageFilename}-thumb.${extension}`;
  }

  public getTranslatedTitle(property: Property): string {
    if (this.translationService.language == "fr") {
      if (property.title_fr) {
        return property.title_fr;
      } else if (property.title_en) {
        return property.title_en;
      } else if (property.title_de) {
        return property.title_de;
      }
    } else if (this.translationService.language == "en") {
      if (property.title_en) {
        return property.title_en;
      } else if (property.title_de) {
        return property.title_de;
      } else if (property.title_fr) {
        return property.title_fr;
      }
    } else if (this.translationService.language == "lb") {
      if (property.title_de) {
        return property.title_de;
      } else if (property.title_fr) {
        return property.title_fr;
      } else if (property.title_en) {
        return property.title_en;
      }
    }
    return "";
  }

  public resetService() {
    this.myProperties = [];
    this.hasProperties = false;
  }
}
