import { DOCUMENT } from "@angular/common";
import { Injectable, Inject } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  private showSpinner: number = 0; // 0 means dont show the spinner and otherwise yes. This ensure that if multiple endpoint activate the spinner we have to wait until the last on to actually deactivate it
  public mode: "indeterminate" | "determinate" = "indeterminate";
  public percentage: number = 0;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public enableSpinner() {
    // We reset the text to nothing in case it should have been set
    let textDiv = this.document.getElementById("spinner-progress-text");
    if (textDiv) {
      textDiv.innerHTML = "";
    }

    // Remove the class in case we were previously in a hide state
    if (!this.showSpinner) {
      this.document.getElementById("spinner")?.classList.remove("hidden");
      this.document.getElementById("spinner")?.classList.add("flex");
    }
    // And increase the counter either way
    this.showSpinner += 1;
  }

  public disableSpinner() {
    if (this.showSpinner > 0) {
      this.showSpinner -= 1;
    }
    // Remove the class whenever we reach 0
    if (this.showSpinner <= 0) {
      this.document.getElementById("spinner")?.classList.add("hidden");
      this.document.getElementById("spinner")?.classList.remove("flex");
      if (this.mode == "determinate") {
        this.mode = "indeterminate";
        this.percentage = 0;
      }
    }
  }

  // Sets the progress of the progress bar in percentage
  // Also allows setting of text to the spinner
  public setProgress(value: number | null, text: string = "") {
    // In case we have added a value we change the mode
    if (value != null) {
      // Show the progress bar in case it is not shown
      if (this.mode != "determinate") {
        this.mode = "determinate";

        // And set the amount
        if (this.mode == "determinate") {
          // Set the width of the bar
          if (value > 100) {
            value = 100;
          } else if (value < 0) {
            value = 0;
          }
          this.percentage = value;
        }
      }

      // And add the text
      this.document.getElementById("spinner-progress-text")!.innerHTML = text;
    }
  }
}
